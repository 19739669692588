import styles from './ContactUs.module.css';

const ContactUs = () => {

    return(
        <>
        <div className={styles.container}>
            <div className={styles.content}>
                <h2>
                    <img className={styles.a_logo} src={process.env.PUBLIC_URL+"img/kellylogo.png"} alt='Kelly logo' />
                    <span className={styles.kelly}>Kelly</span> 
                    <span className={styles.pharma}> Pharmaceuticals Pvt. Ltd.</span>
                </h2>
                <address>
                    <span className={styles.a_text1} >A-36,</span> 
                    <span className={styles.a_text2}>Deepak Vihar,</span> 
                    <span className={styles.a_text3}>Uttam Nagar,</span> 
                    <span className={styles.a_text4}>New Delhi-10059</span> 
                </address>
            </div>
            <div className={styles.iframe}>
            <iframe className={styles.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28015.482418748707!2d77.03674314174482!3d28.631701087076884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d05b0fea16661%3A0xc2fb0d37735a96a8!2sDeepak%20vihar!5e0!3m2!1sen!2sin!4v1680446530999!5m2!1sen!2sin" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Office Location'></iframe>
            </div>
        </div>
        </>
    );
};

export default ContactUs;
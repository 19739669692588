import styles from './Home.module.css'
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

const Home = () => {

    return(
        <>
        <div className={styles.container}>
          <div className={styles.col_1}>
            <img src={process.env.PUBLIC_URL+"img/kfront_2.jpg"} className={styles.bg_width} alt='Front2'></img>
            <img src={process.env.PUBLIC_URL+"img/kfront_1.jpg"} className={styles.bg_width} alt='Front1'></img>
          </div>
          <div className={styles.col_1}>
            <img src={process.env.PUBLIC_URL+"img/kfront_3.jpg"} className={styles.bg_width} alt='Front2'></img>
            <img src={process.env.PUBLIC_URL+"img/kfront_4.jpg"} className={styles.bg_width} alt='Front1'></img>
          </div>
        {/* <img src={process.env.PUBLIC_URL+"img/kfront.jpg"} alt='FrontBackground' className={styles.bg_width}/> */}
        <Link to="/products" className={styles.product_btn}>Explore Products &nbsp;<Icon.ArrowRight/></Link>
        </div>
        <div className={styles.bg_color}>
        <div id="carouselExampleAutoplaying" className={`carousel slide ${styles.size}`} data-bs-ride="carousel">
            <div className={`carousel-indicators ${styles.btn_orange}`}>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2" aria-label="Slide 3"></button>
              <button type="button"  data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="3" aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="4" aria-label="Slide 5"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="5" aria-label="Slide 6"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="6" aria-label="Slide 7"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="7" aria-label="Slide 8"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="8" aria-label="Slide 9"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="9" aria-label="Slide 10"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="10" aria-label="Slide 11"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="11" aria-label="Slide 12"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="12" aria-label="Slide 13"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="13" aria-label="Slide 14"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="14" aria-label="Slide 15"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="15" aria-label="Slide 16"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="16" aria-label="Slide 17"></button>
              <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="17" aria-label="Slide 18"></button>
            </div>
            <div className={`carousel-inner ${styles.inner}  ${styles.carimg}`}>
              <div className={`carousel-item active ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/krab_20_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/krab_dsr_car.jpg"} className={`d-block w-100 `} alt="carousel" />
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/krab_l_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/utrue_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kfull_cap_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kfull_syp_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kfull_jr_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kzinc_syp_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/ptrue_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kcef_200_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kcef_cv_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kcef_cv_100_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/knerv_inj_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kfer_xt_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/rbone_ct_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/kril_sp_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/qjem_forte_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
              <div className={`carousel-item ${styles.items}`}>
                <img src={process.env.PUBLIC_URL+"img/mtln_car.jpg"} className={`d-block w-100 `} alt="carousel"/>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
              <span aria-hidden="true"><Icon.ArrowLeftCircle className='text-success h1'/></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
              <span  aria-hidden="true"><Icon.ArrowRightCircle className='text-success h1'/></span>
              <span className="visually-hidden">Next</span>
            </button>
        </div>
        </div>
        </>
    );
};

export default Home;

import {Routes, Route} from 'react-router-dom'
import Home from './component/Home/Home';
import AboutUs from './component/AboutUs/AboutUs';
import Products from './component/Products/Product';
import ContactUs from './component/ContactUs/ContactUs';
import Navigation from './component/Navigation/Navigation';



function App() {


  return (
    <>
      <Navigation title ="Kelly Pharma"/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<AboutUs />} />
      <Route path='/products' element={<Products />} />
      <Route path='/contact' element={<ContactUs />} />
    </Routes>
    </>
  );
}

export default App;


import { Link } from 'react-router-dom';
import styles from './Navigation.module.css';


const Navigation = (props) => {




    return (
        <>
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
            <div className="container-fluid text-center">
                <Link className= {`navbar-brand ${styles.moveinleft} ${styles.color_primary} ${styles.pos}`}assName="navbar-brand">{props.title}</Link>
                <img src={process.env.PUBLIC_URL+"img/kellylogo.png"} alt="logo" className={`${styles.moveinleft} ${styles.img}`}/>
                <button className={`navbar-toggler ${styles.tcolor}`}type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${styles.justify}`} id="navbarNav">
                    <ul className="navbar-nav mx-3">
                        <li className={`nav-item ${styles.btnNav}`}>
                            <Link className={`nav-link ${styles.actlink}`} aria-current="page" to="/">Home</Link>
                        </li>
                        <li className={`nav-item ${styles.btnNav}`}>
                            <Link className={`nav-link ${styles.actlink} `} to="/about">About Us</Link>
                        </li>
                        <li className={`nav-item ${styles.btnNav}`}>
                            <Link className={`nav-link ${styles.actlink}`} to="/products">Products</Link>
                        </li>
                        <li className={`nav-item ${styles.btnNav}`}>
                            <Link className={`nav-link ${styles.actlink}`} to='/contact'>Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </>
    );
};

export default Navigation;
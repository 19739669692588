import * as Icon from 'react-bootstrap-icons';
import React from 'react';
import styles from './AboutUs.module.css'

const AboutUs = () => {
    return (
        <React.Fragment>
         <div className={styles.container}>
         <div className={styles.aboutImg1}>
            <img src={process.env.PUBLIC_URL+"img/aboutImg_2.jpg"} alt="backImg" />
         </div>
         <div className={styles.about_main_content1}>
            <h2 className={styles.header_main}>About Us</h2>
            <p>Kelly Pharmaceutical Pvt. Ltd. is one of the fastest growing pharmaceutical company that specializes in the research, development, manufacturing, and distribution of high-quality pharmaceutical drugs and medications. With a strong focus on innovation and customer satisfaction, Kelly Pharmaceutical is committed to providing safe, effective, and affordable healthcare solutions to patients in India.</p>
            <p>Kelly Pharmaceutical is committed to maintaining the highest ethical standards in all its operations. The company adheres to strict regulatory requirements and guidelines, ensuring that its products are safe, effective, and comply with all applicable laws and regulations.</p>
            <p>With a strong focus on customer satisfaction, Kelly Pharmaceutical provides excellent customer service, offering support and education to healthcare professionals and patients alike. The company is dedicated to improving the health and well-being of people worldwide, and its products are trusted by healthcare professionals and patients in India.</p>
            {/* <p>Kelly pharmaceuticals Pvt. Ltd. is one of the fastest growing privately held pharmaceutical company in India, headquartered at New Delhi. It has been developing pharmaceutical products and selling and distributing these all over India. Kelly Pharmaceuticals is an emerging pharmaceutical company which strives to achieve perfection in all that it does. It is an organization which integrates people, processes and potential towards the betterment of human society.</p>
            <p>We are truly dedicated to healthcare, for us healthcare means not only business but serving social good, so we have a far deeper reason than profits alone to drive our performance.</p> */}
         </div>
         </div>
         <div className={styles.container}>
            <div className={styles.aboutImg2}>
            <img src={process.env.PUBLIC_URL+"img/back1.jpg"} alt="backImg" />
            </div>
            <div className={styles.about_main_content2}>
                <h2 className={styles.header_main}>Our Values</h2>
                <h4 className={styles.header_tertiary}>Quality</h4>
                <p><Icon.SymmetryHorizontal className='text-success'/>&nbsp; Ensuring that all products are of the highest quality and meet regulatory requirements.</p>
                <h4 className={styles.header_tertiary}>Innovation</h4>
                <p><Icon.SymmetryHorizontal className='text-success'/>&nbsp; Encouraging creativity and new ideas to develop cutting-edge drugs and therapies that improve health outcomes</p>
                <h4 className={styles.header_tertiary}>Patient-Centeredness</h4>
                <p><Icon.SymmetryHorizontal className='text-success'/>&nbsp; Focusing on the needs of patients, and striving to improve their quality of life through the development of safe and effective drugs.</p>
                <h4 className={styles.header_tertiary}>Ethics</h4>
                <p><Icon.SymmetryHorizontal className='text-success'/>&nbsp; Conducting business in an ethical and socially responsible manner, with transparency and honesty.</p>
                <h4 className={styles.header_tertiary}>Continuous Improvement</h4>
                <p><Icon.SymmetryHorizontal className='text-success'/>&nbsp; Seeking to continuously improve processes and products to ensure the highest level of efficiency and effectiveness.</p>
            </div>
         </div>
         
        </React.Fragment>
    );
};

export default AboutUs;
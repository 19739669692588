import styles from './Products.module.css';

const Products = () => {

    return (
        <>
        <div className={styles.product_section} >
                <div className={styles.row}>
                    <div className={`${styles.col_1} ${styles.position}`}>
                        <h3>Gastro</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p className={styles.padd}>Kelyrab-20 Tablets</p>
                                <p className={styles.pos}>Each enteric coated tablet contains:
                                Rabeprazole Sodium IP 20mg</p>
                            </div>                            
                            <div className={`${styles.disp2}`}>
                                <p className={styles.padd}>Kelyrab-DSR Capsules</p>
                                <p className={styles.pos}>Each capsule contains:  
                                Rabeprazole Sodium IP (as enteric coated pellets) 20mg
                                Domperidone IP (as sustained release pellets) 30mg</p>
                            </div>                            
                            <div className={`${styles.disp2}`}>
                                <p className={styles.padd}>Kelyrab-L Capsules</p>
                                <p className={styles.pos}>Each capsule contains:
                                Rabeprazole Sodium IP (as enteric coated pellets) 20mg
                                Levosulpiride (as sustained release pellets) 75mg</p>
                            </div>                            
                            <div className={`${styles.disp2}`}>
                                <p>Uditrue Tablets</p>
                                <p className={styles.pos}>Each enteric coated tablet contains:
                                Ursodeoxycholic Acid 300mg</p>
                            </div>                            
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img className={`${styles.col_2_1} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/krab_20.jpg"} alt="Kelyrab-20"/>
                        <img className={`${styles.col_2_2} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/krab_dsr.jpg"} alt="Kelyrab-DSR" />
                        <img className={`${styles.col_2_3} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/krab_l.jpg"} alt="Kelyrab-L" />
                        <img className={`${styles.col_2_4} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/utrue_1.jpg"} alt="Uditrue" />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.reverse}`}>
                    <div className={`${styles.col_3} ${styles.position}`}>
                        <h3>Nutraceutical</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2} ${styles.over} `}>
                                <p>Kelyfull Syrup</p>
                                <p className={`${styles.pos}`}>Each 10 ml contains: 
                                Lycopene (6%) 1000mcg,
                                L-Lysine Mono HCL 5mg,
                                Vitamin A (Palmitate) 1600IU,
                                Vitamin B1 2mg,
                                Vitamin B2 3mg,
                                Vitamin B6 1.5mg,
                                Vitamin B12 1mcg,
                                Vitamin D3 100IU,
                                Vitamin E (Acetate) 10IU,
                                Methylcobalamin 250mcg,
                                Selenium Dioxide (eq. to Elemental Selenium) 35mcg,
                                Zinc (as Zinc Gluconate) 5mg,
                                Manganese Sulphate 2mg,
                                Iodine (as Potassium Iodine) 1000mcg,
                                Copper (as Copper Sulphate) 500mcg,
                                In Mango Flavoured base q.s.</p>
                            </div>                            
                            <div className={`${styles.disp2} ${styles.over1}`}>
                                <p className={styles.padd}>Kelyfull Softgel Capsules</p>
                                <p className={styles.pos}>	Each softgel capsule contains:
                                Lycopene 10%  USP 4000mcg,
                                Lutien 10%  USP 4000mcg,
                                Betacarotine 30%  USP 5.17mg,
                                Black Grape Seed Extract 10mg
                                Selenium Dioxide Monohydrate USP 25mcg,
                                Zinc Sulphate Monohydrate IP 10mg,
                                Excipients q.s.</p>
                            </div>                            
                            <div className={`${styles.disp2} ${styles.over2}`}>
                                <p className={styles.padd}>Kelyfull Jr. Syrup</p>
                                <p className={styles.pos}>Each 5 ml contains: 
                                L-Lysine HCL 5mg,
                                Vitamin A (Palmitate) 1600IU,
                                Vitamin B1 2mg,
                                Vitamin B2 1mg,
                                Vitamin B5 1.25mg,
                                Vitamin B6 0.5mg,
                                Vitamin B12 0.5mcg,
                                Vitamin D3 100IU,
                                Vitamin E (Acetate) 5IU,
                                Niacinamide 15 mg,
                                Zinc Sulphate Monohydrate 5mg,
                                Sodium Selenite 10mcg,
                                Potassium Sulphate 100mcg,
                                Copper Sulphate 100mcg,
                                Excipients</p>
                            </div>                            
                            <div className={`${styles.disp2} ${styles.over3}`}>
                                <p>Kelyzinc Syrup</p>
                                <p className={styles.pos}>Each 5 ml contains:
                                Zinc Acetate(as Dihydrate) eq. to elemental Zinc 20 mg</p>
                            </div>                            
                            <div className={`${styles.disp2} ${styles.over4} `}>
                                <p>Ptrue Powder</p>
                                <p className={styles.pos}>Each 30g Powder contains:
                                Protien Hydrolysate 20% 5mg,
                                DHA 10% 25mg,
                                Calcium 58.92mg,
                                Phosphorus 45.57mg,
                                Niacinamide 14mg,
                                Sodium 12.98mg,
                                Potassium 2.62mg,
                                Magnesium 2.41mg,
                                Copper 1mg,
                                Zinc 0.55mg,
                                Pyridoxine 0.41mg,
                                Folic Acid 176.47mcg,
                                Iodine 76.45mcg,
                                Selenium 9.13mcg,
                                Chromium 8.21mcg,
                                Manganese 1mcg,
                                cyanocobalamin 1mcg
                                </p>
                            </div>                            
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img  className={`${styles.col_4_1} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kfull_syp.jpg"} alt="Kelyfull Syp" />
                        <img className={`${styles.col_4_2} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kfull_cap.jpg"} alt="Kelyfull Cap" />
                        <img className={`${styles.col_4_3} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kzinc_syp.jpg"} alt="Kelyzinc" />
                        <img className={`${styles.col_4_4} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kfull_jr.jpg"} alt="Kelyfull-jr" />
                        <img className={`${styles.col_4_5} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/ptrue_pow.jpg"} alt="Ptrue" />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.col_5} ${styles.position}`}>
                        <h3>Antibiotics</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p className={styles.padd}>Kelycef-200 Tablets</p>
                                <p className={styles.pos}>	Each film coated tablet contains:
                                Cefpodoxime Proxetil IP eq. to
                                Cefpodoxime 200mg</p>
                            </div>                            
                            <div className={`${styles.disp2} ${styles.over5}`}>
                                <p className={styles.padd}>Kelycef-CV Tablets</p>
                                <p className={styles.pos}>Each film coated tablet contains:
                                Cefpodoxime Proxetil IP eq. to
                                Cefpodoxime 200mg
                                Potassium Clavulanate IP (as Potassium Clavulanate Diluted IP) eq. to
                                Clavulanic Acid 125mg</p>
                            </div>                            
                            <div className={`${styles.disp2}`}>
                                <p className={styles.padd}>Kelycef-CV 100 Dry Syrup</p>
                                <p className={styles.pos}>Each 5ml after reconstitution contains:
                                Cefpodoxime Proxetil IP eq. to
                                Cefpodoxime 100mg
                                Potassium Clavulanate IP (as Potassium Clavulanate Diluted IP) eq. to
                                Clavulanic Acid 62.5mg
                                </p>
                            </div>                                                       
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img className={`${styles.col_6_1} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kcef_200.jpg"} alt="Kelcef-200"/>
                        <img className={`${styles.col_6_2} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kcef_cv.jpg"} alt="Kelcef-CV"/>
                        <img className={`${styles.col_6_3} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kcef_cv_100.jpg"} alt="Kelcef-CV 100 "/>
                    </div>
                </div>
                <div className={`${styles.row} ${styles.reverse}`}>
                    <div className={`${styles.col_7} ${styles.position}`}>
                        <h3>Neuropathic</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p>Kelynerv Injection</p>
                                <p className={styles.pos}>Each vial contains:
                                Methylcobalamin 1500mcg,
                                Nicotinamide 100mg,
                                Pyridoxine 100mg</p>
                            </div>                                                        
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p className={styles.padd}>Mayosafe-PG Capsules</p>
                                <p className={styles.pos}>Each Hard gelatin capsules contains:
                                Pregabalin IP 75mg,
                                Methylcobalamin IP 750mcg,
                                Excipients q.s</p>
                            </div>                                                        
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img className={`${styles.col_8_1} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/knerv_inj.jpg"} alt="Kelynerv-Inj"/>
                        <img className={`${styles.col_8_2} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/mayo_pg.jpg"} alt="Mayosafe-PG"/>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.col_9} ${styles.position}`}>
                        <h3>Haematonic</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p>Keyfer-XT Tablets</p>
                                <p className={styles.pos}>Each film coated tablet contains:
                                Ferrous Ascorbate eq. to
                                elemental Iron 100 mg,
                                Folic Acid IP 1.5 mg,
                                Zinc Sulphate 61.8mg
                                </p>
                            </div>                                                      
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img className={`${styles.col_10} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kfer_xt.jpg"} alt="Keyfer-XT" />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.reverse}`}>
                    <div className={`${styles.col_11} ${styles.position}`}>
                        <h3>Antiostioporotic</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p className={styles.padd}>Ridabone-CT Softgel Capsules</p>
                                <p className={styles.pos}>Each soft gelatin capsule contains:
                                Calcitriol IP 0.25mcg,
                                Calcium Carbonate IP 500mg,
                                Zinc Sulphate Monohydrate USP: eq. To elemental Zinc 7.5mg</p>
                            </div>                                                      
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img className={`${styles.col_12} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/rbone_ct.jpg"} alt="Ridabone-CT" />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.col_13} ${styles.position}`}>
                        <h3>Analgesic</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p>Kwikril-SP Tablets</p>
                                <p className={styles.pos}>	Each film coated tablet contains:
                                Aceclofenac 100mg,
                                Paracetamol 325mg,
                                Serratiopeptidase 15mg</p>
                            </div>                           
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img className={`${styles.col_14} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/kril_sp.jpg"} alt="Kwikril-SP"/>
                    </div>
                </div>
                <div className={`${styles.row} ${styles.reverse}`}>
                    <div className={`${styles.col_15} ${styles.position}`}>
                        <h3>Infertility</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p className={styles.padd}>Qjem-Forte Tablets</p>
                                <p className={styles.pos}>Each enteric coated tablet contains:
                                Levocarnitine 500mg,
                                Co-Enzyme Q10 100mg,
                                Lycopene 4000 mcg,
                                Vitamin E 60mg,
                                L-Arginine 100mg,
                                Zinc 62.5gm,
                                Magnesium 50mg,
                                Omega 3 Fatty Acid eq. EPA 90mg,
                                DHA 60 mg,
                                Selenium 70mg</p>
                            </div>                           
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img className={`${styles.col_16} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/qjem_forte.jpg"} alt="Qjem-Forte"/>
                    </div>
                </div>
                <div className={`${styles.row}`}>
                    <div className={`${styles.col_17} ${styles.position}`}>
                        <h3>Antiallergic</h3>
                        <div className={`${styles.disp1}`}>
                            <h5>Name</h5>
                            <h5 className={styles.pos}>Composition</h5>
                        </div>
                        <div>
                            <div className={`${styles.disp2}`}>
                                <p>MTLN Tablets</p>
                                <p className={styles.pos}>Each uncoated bi-layered tablet contains:
                                Montelukast Sodium eq. to
                                Montelukast: 10mg,
                                Levocetirizine Dihydrochloride IP 5mg</p>
                            </div>                           
                        </div>
                    </div>
                    <div className={`${styles.wt}`}>
                        <img className={`${styles.col_18} ${styles.hover} ${styles.trans}`} src={process.env.PUBLIC_URL+"img/mtln.jpg"} alt="MTLN"/>
                    </div>
                </div>
        </div>
        </>
    );
};

export default Products;